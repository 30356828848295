import Text from "@components/Text";
import React from "react";
import Card from "@components/Card";
import { observer } from "mobx-react-lite";
import StatisticsGroup from "@components/StatisticsGroup";
import SizedBox from "@components/SizedBox";
import { useExploreTokenVM } from "@screens/ExploreToken/ExploreTokenVm";
import BN from "@src/utils/BN";

const ExploreTokenPriceStatistics = () => {
  const vm = useExploreTokenVM();
  return (
    <Card style={{ flex: 1 }}>
      <Text weight={500} style={{ fontSize: 24, lineHeight: "32px" }}>
        Market details
      </Text>
      <SizedBox height={24} />
      <Text weight={500}>General info</Text>
      <StatisticsGroup
        data={vm.statistics ? [ {
            title: "Total supply",
            value:
                BN.formatUnits(
                    vm.statistics.totalSupply,
                    vm.statistics.decimals
                ).toFormat(2) + ` ${vm.statistics.symbol}`
        },
            {
                title: "Total borrow",
                value:
                    BN.formatUnits(
                        vm.statistics.totalBorrow,
                        vm.statistics.decimals
                    ).toFormat(2) + ` ${vm.statistics.symbol}`
            },
            {
                title: "Utilization ratio",
                value:
                    vm.statistics.totalBorrow.gt(0) ? vm.statistics.totalBorrow
                        .div(vm.statistics.totalSupply)
                        .times(100)
                        .toFixed(2) + " %" : "0%"
            },
            {
                title: "Reserves",
                value:
                    BN.formatUnits(
                        vm.statistics.totalSupply.minus(vm.statistics.totalBorrow),
                        vm.statistics.decimals
                    ).toFormat(2) + ` ${vm.statistics.symbol}`
            },
            { title: "Supply APY", value: vm.statistics.supplyAPY.toFormat(2) + " %" },
            { title: "Supply APR", value: vm.statistics.supplyAPR.toFormat(2) + " %" },
            { title: "Borrow APY", value: vm.statistics.borrowAPY.toFormat(2) + " %" },
            { title: "Borrow APR", value: vm.statistics.borrowAPR.toFormat(2) + " %" },
          {
            title: "Price",
            value: `$${vm.statistics?.prices.min.toFormat(4)}`
          },
          { title: "Number of suppliers", value: vm.users.supply.toFormat(0) },
          { title: "Number of borrowers", value: vm.users.borrow.toFormat(0) }
        ] : []}
      />
    </Card>
  );
};

export default observer(ExploreTokenPriceStatistics);
