import styled from "@emotion/styled";
import Layout from "@components/Layout";
import Text from "@components/Text";
import SizedBox from "@components/SizedBox";
import Card from "@components/Card";
import { Column, Row } from "@components/Flex";

import { ReactComponent as Plus } from "@src/assets/icons/plus.svg";
import { ReactComponent as ArrowUpCircle } from "@src/assets/icons/arrowUpCircle.svg";

import { observer } from "mobx-react-lite";
import MarketCard from "./MarketCard/MarketCard";
import { IPool, POOLS } from "@src/constants";
import { useStores } from "@src/stores";
import BN from "@src/utils/BN";
import React from "react";
import Skeleton from "react-loading-skeleton";

const Root = styled.div<{ apySort?: boolean; liquiditySort?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 16px;
  width: 100%;
  min-height: 100%;
  max-width: calc(1328px + 32px);
  margin-bottom: 72px;
  margin-top: 40px;
  text-align: left;

  @media (min-width: 560px) {
    margin-top: 60px;
  }

  @media (min-width: 880px) {
    margin-bottom: 96px;
  }
`;

const Subtitle = styled(Text)`
  @media (min-width: 880px) {
    max-width: 560px;
  }
`;

const Statistics = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media (min-width: 880px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const SquareIcon = styled.div<{ type?: "success" | "error" }>`
  display: flex;

  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  object-fit: cover;
  border-radius: 10px;
  
  background: ${({ theme, type }) => {
        switch (type) {
            case "success":
                return theme.colors.success100;
            case "error":
                return theme.colors.error100;
        }
    }};
`;

const Markets = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media (min-width: 880px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const promisesUpdateSpeed = 500;

const DashboardHome: React.FC<{}> = () => {
    const { lendStore } = useStores();

    const [overallSupply, setOverallSupply] = React.useState(BN.ZERO);
    const fetchOverallSupply = async () => {
        while (overallSupply.isZero()) {
            await new Promise(resolve => setTimeout(resolve, promisesUpdateSpeed));
            setOverallSupply(await lendStore.getOverallSupply());
        }
    }

    const [overallBorrow, setOverallBorrow] = React.useState(BN.ZERO);
    const fetchOverallBorrow = async () => {
        while (overallBorrow.isZero()) {
            await new Promise(resolve => setTimeout(resolve, promisesUpdateSpeed));
            setOverallBorrow(await lendStore.getOverallBorrow());
        }
    }

    Promise.all([
        fetchOverallSupply(),
        fetchOverallBorrow(),
    ])

    return (
        <Layout>
            <Root>
                <Text weight={500} size="large">Lending protocol built on the<a
                    style={{ color: "#7075E9", paddingLeft: 4 }}
                    href="https://waves.tech/"
                    target="_blank"
                    rel="noreferrer"
                    >Waves blockchain</a>
                </Text>
                <SizedBox height={4} />
                <Subtitle fitContent>
                Supply and borrow tokens using different markets
                </Subtitle>
                <SizedBox height={16} />
                <Statistics>
                    <Card>
                        <Row style={{ gap: 10, alignItems: "center" }}>
                            <Column style={{ flexGrow: 1 }}>
                                <Text type="secondary" size="bigger">Total supplied</Text>
                                {
                                    overallSupply.isZero()
                                        ? <Skeleton height={24} width={64}></Skeleton>
                                        : <Text type="primary" size="large" weight={500}>$ {overallSupply.toBigFormat(2)}</Text>
                                }
                            </Column>
                            <SquareIcon type="success">
                                <Plus />
                            </SquareIcon>
                        </Row>
                    </Card>
                    <Card>
                        <Row style={{ gap: 10, alignItems: "center" }}>
                            <Column style={{ flexGrow: 1 }}>
                                <Text type="secondary" size="bigger">Total borrowed</Text>
                                {
                                    overallBorrow.isZero()
                                        ? <Skeleton height={24} width={64}></Skeleton>
                                        : <Text type="primary" size="large" weight={500}>$ {overallBorrow.toBigFormat(2)}</Text>
                                }
                            </Column>
                            <SquareIcon type="error">
                                <ArrowUpCircle />
                            </SquareIcon>
                        </Row>
                    </Card>
                </Statistics>
                <SizedBox height={24} />
                <Markets>
                    {POOLS.map((pool: IPool) => (
                        <MarketCard key={pool.name} pool={pool} />
                    ))}
                </Markets>
            </Root>
        </Layout>
    );
}

export default observer(DashboardHome);
