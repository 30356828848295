import styled from "@emotion/styled";
import Button from "@components/Button";
import { Column } from "@components/Flex";
import SizedBox from "@components/SizedBox";
import Text from "@components/Text";
import { useStores } from "@src/stores";
import { IPool } from "@src/constants";
import React from "react";
import BN from "@src/utils/BN";
import useWindowSize from "@src/hooks/useWindowSize";
import DesktopMarketCardHeader from "./DesktopMarketCardHeader";
import MobileMarketCardHeader from "./MobileMarketCardHeader";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

interface IProps {
  pool: IPool;
}

const MarketCardRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.card.background};
  overflow: hidden;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
`;

const SupplyBorrowHealth = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  
  @media (min-width: 880px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const promisesUpdateSpeed = 100;

const MarketCard: React.FC<IProps> = ({ pool }) => {
    const { width } = useWindowSize();
    const { lendStore } = useStores();

    const [totalSupply, setTotalSupply] = React.useState(new BN(-2));
    const fetchTotalSupply = async () => {
        if (totalSupply.eq(-2)) {
            setTotalSupply(await lendStore.getTotalSupply(pool.address));
        }
        while (totalSupply.eq(-2)) {
            await new Promise(resolve => setTimeout(resolve, promisesUpdateSpeed));
            setTotalSupply(await lendStore.getTotalSupply(pool.address));
        }
    }

    const [totalBorrow, setTotalBorrow] = React.useState(new BN(-2));
    const fetchTotalBorrow = async () => {
        if (totalBorrow.eq(-2)) {
            setTotalBorrow(await lendStore.getTotalBorrow(pool.address));
        }
        while (totalBorrow.eq(-2)) {
            await new Promise(resolve => setTimeout(resolve, promisesUpdateSpeed));
            setTotalBorrow(await lendStore.getTotalBorrow(pool.address));
        }
    }

    const [totalAccountSupply, setTotalAccountSupply] = React.useState(new BN(-2));
    const fetchTotalAccountSupply = async () => {
        if (totalAccountSupply.eq(-2)) {
            setTotalAccountSupply(await lendStore.getTotalAccountSupply(pool.address));
        }
        while (totalAccountSupply.eq(-2)) {
            await new Promise(resolve => setTimeout(resolve, promisesUpdateSpeed));
            setTotalAccountSupply(await lendStore.getTotalAccountSupply(pool.address));
        }
    }

    const [totalAccountBorrow, setTotalAccountBorrow] = React.useState(new BN(-2));
    const fetchTotalAccountBorrow = async () => {
        if (totalAccountBorrow.eq(-2)) {
            setTotalAccountBorrow(await lendStore.getTotalAccountBorrow(pool.address));
        }
        while (totalAccountBorrow.eq(-2)) {
            await new Promise(resolve => setTimeout(resolve, promisesUpdateSpeed));
            setTotalAccountBorrow(await lendStore.getTotalAccountBorrow(pool.address));
        }
    }

    const [maxSupplyAPY, setMaxSupplyAPY] = React.useState(new BN(-2));
    const fetchMaxSupplyAPY = async () => {
        if (maxSupplyAPY.eq(-2)) {
            setMaxSupplyAPY(await lendStore.getMaxSupplyAPY(pool.address));
        }
        while (maxSupplyAPY.isZero()) {
            await new Promise(resolve => setTimeout(resolve, promisesUpdateSpeed));
            setMaxSupplyAPY(await lendStore.getMaxSupplyAPY(pool.address));
        }
    }

    const [health, setHealth] = React.useState(new BN(-2));
    const fetchHealth = async () => {
        if (health.eq(-2)) {
            setHealth(await lendStore.getHealthAsync(pool.address));
        }
        while (health.eq(-2)) {
            await new Promise(resolve => setTimeout(resolve, promisesUpdateSpeed));
            setHealth(await lendStore.getHealthAsync(pool.address));
        }
    }

    Promise.all([
        fetchTotalSupply(),
        fetchTotalBorrow(),
        fetchTotalAccountSupply(),
        fetchTotalAccountBorrow(),
        fetchMaxSupplyAPY(),
        fetchHealth(),
    ])

    return (
        <MarketCardRoot>
            {width && width < 880
                ? <MobileMarketCardHeader
                    pool={pool}
                    totalSupply={totalSupply}
                    totalBorrow={totalBorrow}
                    maxSupplyAPY={maxSupplyAPY}
                />
                : <DesktopMarketCardHeader
                    pool={pool}
                    totalSupply={totalSupply}
                    totalBorrow={totalBorrow}
                    maxSupplyAPY={maxSupplyAPY}
                />}
            <Actions>
                <SupplyBorrowHealth>
                    <Column>
                        <Text type="secondary" size="medium">My supply balance</Text>
                        <Text size="medium+" weight={500}>{ totalAccountSupply.lt(0) ? "—" : "$ " + totalAccountSupply.toBigFormat(2) }</Text>
                    </Column>
                    <Column>
                        <Text type="secondary" size="medium">My borrowed balance</Text>
                        <Text size="medium+" weight={500}>{ totalAccountBorrow.lt(0) ? "—" : "$ " + totalAccountBorrow.toBigFormat(2) }</Text>
                    </Column>
                    <Column>
                        <Text type="secondary" size="medium">Account health</Text>
                        <Text size="medium+" weight={500}>{ health.eq(-2) ? <Skeleton height={24} width={64} /> : health.isZero() ? "—" : health.toBigFormat(2) + "%" }</Text>
                    </Column>
                </SupplyBorrowHealth>
                <SizedBox height={16} />
                <Link to={`/${pool.address}`}>
                    <Button kind="secondary" size="large" fixed>Go to market</Button>
                </Link>
            </Actions>
        </MarketCardRoot>
    );
}

export default MarketCard
