import tokens from "./tokens.json";
import tokenLogos from "@src/constants/tokenLogos";

export enum OPERATIONS_TYPE {
  WITHDRAW = "withdraw",
  SUPPLY = "supply",
  BORROW = "borrow",
  REPAY = "repay"
}

export const ROUTES = {
  ROOT: "/",
  DASHBOARD: "/",
  DASHBOARD_MODAL_WITHDRAW: "",
  DASHBOARD_POOL: "/:poolId",
  DASHBOARD_MODALS: {
    [OPERATIONS_TYPE.SUPPLY]: "supply/:tokenId",
    [OPERATIONS_TYPE.WITHDRAW]: "withdraw/:tokenId",
    [OPERATIONS_TYPE.BORROW]: "borrow/:tokenId",
    [OPERATIONS_TYPE.REPAY]: "repay/:tokenId"
  },
  DASHBOARD_TOKEN_DETAILS: "/:poolId/:assetId",
  ANALYTICS: "/analytics",
  NOT_FOUND: "/404"
};

export const POOLS_PROD: IPool[] = [
  {
    name: "Main Market",
    address: "3P4uA5etnZi4AmBabKinq2bMiWU8KcnHZdH",
    description: "The simplest and oldest market serving users for more than 2 years and consisting of most reliable assets with modest APYs.",
  },
  {
    name: "ROME Market",
    address: "3P8Df2b7ywHtLBHBe8PBVQYd3A5MdEEJAou",
    description: "The market used to mint ROME stablecoin by adding LP tokens in collateral. The LPs APY is boosted by donated gWX.",
  },
  {
    name: "Waves DeFi",
    address: "3P4DK5VzDwL3vfc5ahUEhtoe5ByZNyacJ3X",
    description: "The second oldest market consisting of different tokens from Waves Defi landscape.",
  },
  {
    name: "Low Cap",
    address: "3PHpuQUPVUoR3AYzFeJzeWJfYLsLTmWssVH",
    description: "The most yield generating market consisting of assets with higher volatility. Use it with caution.",
  },
  // {
  //   name: "Axly Market",
  //   address: "3PAd9Aqg3cQSzu26MFYWQYcjSXPVCJtuf5V"
  // },
  // {
  //   name: "Global Pool",
  //   address: "3PJPpKuM7NTC9QaDqAPY4V8wtRWWmLBSSsY"
  // },
];

export const POOLS_DEV = POOLS_PROD;
  //   {
  // name: "Test LP Market",
  // address: "3PGNZtiG6xdG5qb66MimGm8Xh6UzKitkCzt"
  // },
//   {
//     name: "Main Market",
//     address: "3P4uA5etnZi4AmBabKinq2bMiWU8KcnHZdH"
//   },
//   {
//     name: "ROME Market",
//     address: "3P8Df2b7ywHtLBHBe8PBVQYd3A5MdEEJAou"
//   },
//   // {
//   //   name: "Puzzle Pool",
//   //   address: "3P6dkRGSqgsNpQFbSYn9m8n4Dd8KRaj5TUU"
//   // },
//   {
//     name: "Waves DeFi Pool",
//     address: "3P4DK5VzDwL3vfc5ahUEhtoe5ByZNyacJ3X"
//   },
//   {
//     name: "Low Cap",
//     address: "3PHpuQUPVUoR3AYzFeJzeWJfYLsLTmWssVH"
//   },
//   {
//     name: "Axly Pool",
//     address: "3PAd9Aqg3cQSzu26MFYWQYcjSXPVCJtuf5V"
//   },
//   // {
//   //   name: "Global Pool",
//   //   address: "3PJPpKuM7NTC9QaDqAPY4V8wtRWWmLBSSsY"
//   // }
// ];

const POOLS_LIST: Record<string, Array<IPool>> = {
  PROD: POOLS_PROD,
  DEV: POOLS_DEV
};

export const POOLS = POOLS_LIST[process.env.REACT_APP_NODE_ENV ?? "DEV"];

export const TOKENS_LIST: Array<IToken> = Object.values(tokens).map((t) => ({
  ...t,
  logo: tokenLogos[t.symbol]
}));
export const TOKENS_BY_SYMBOL: Record<string, IToken> = TOKENS_LIST.reduce(
  (acc, t) => ({ ...acc, [t.symbol]: t }),
  {}
);
export const TOKENS_BY_ASSET_ID: Record<string, IToken> = TOKENS_LIST.reduce(
  (acc, t) => ({ ...acc, [t.assetId]: t }),
  {}
);

export const NODE_URL = "https://nodes-puzzle.wavesnodes.com";
export const EXPLORER_URL = "https://new.wavesexplorer.com";

export interface IPool {
  name: string;
  address: string;
  description?: string;
}

export interface IToken {
  assetId: string;
  name: string;
  symbol: string;
  decimals: number;
  startPrice?: number;
  description?: string;
  logo: string;
  category?: string[];
}

export enum ASSETS_TYPE {
  SUPPLY_BLOCK,
  HOME,
  BORROW_BLOCK
}
