import React, { useMemo } from "react";
import { useVM } from "@src/hooks/useVM";
import { makeAutoObservable } from "mobx";
import { RootStore, useStores } from "@stores";
import copy from "copy-to-clipboard";
import Balance from "@src/entities/Balance";
import BN from "@src/utils/BN";
import { TPoolStats } from "@src/stores/LendStore";
import { TOKENS_LIST } from "@src/constants";

const ctx = React.createContext<WalletVM | null>(null);
//fixme review wallet and fix issues
export const WalletVMProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const rootStore = useStores();
  const store = useMemo(() => new WalletVM(rootStore), [rootStore]);
  return <ctx.Provider value={store}>{children}</ctx.Provider>;
};

export const useWalletVM = () => useVM(ctx);

class WalletVM {
  rootStore: RootStore;

  headerExpanded = true;
  setHeaderExpanded = (state: boolean) => (this.headerExpanded = state);

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  handleCopyAddress = () => {
    const { accountStore, notificationStore } = this.rootStore;
    if (accountStore.address) {
      copy(accountStore.address ?? "");
      notificationStore.notify("Your address was copied", {
        type: "success",
        title: "Congratulations!"
      });
    } else {
      notificationStore.notify("There is no address", { type: "error" });
    }
  };

  handleLogOut = async () =>
    Promise.all([
      this.rootStore.accountStore.setWalletModalOpened(false),
      this.rootStore.accountStore.setAssetBalances(null),
      this.rootStore.accountStore.setAddress(null),
      this.rootStore.accountStore.setLoginType(null)
    ]);

  get signInInfo() {
    const { signInMethod, addressToDisplay } = this.rootStore.accountStore;
    return `${signInMethod}: ${addressToDisplay}`;
  }

  get userAssets() {
    const { accountStore } = this.rootStore;
    const poolStats =  /:\/\/((\w|[.:])+\/(.+))/.test(window.location.href) ? this.rootStore.lendStore.poolsStats : this.rootStore.lendStore.mergedPoolsStats;
    return TOKENS_LIST.map((t) => {
      const balance = accountStore.findBalanceByAssetId(t.assetId);
      return balance ?? new Balance(t);
    })
      .filter((balance) =>
        poolStats.find((item) => item.assetId === balance.assetId)
      )
      .filter(({ balance }) => balance && !balance.eq(0))
      .sort((a, b) => {
        if (a.usdnEquivalent == null && b.usdnEquivalent == null) return 0;
        if (a.usdnEquivalent == null && b.usdnEquivalent != null) return 1;
        if (a.usdnEquivalent == null && b.usdnEquivalent == null) return -1;
        return a.usdnEquivalent!.lt(b.usdnEquivalent!) ? 1 : -1;
      });
  }

  public async getTotalInvestmentAmount() {
    const { userAssets } = this;
    const balancesAmount = await userAssets.reduce(async (acc, b) => {
      const rate = (await this.tokenStats(b.assetId))?.prices.min || BN.ZERO;
      const balance = BN.formatUnits(b?.balance || BN.ZERO, b?.decimals);
      return (await acc).plus(balance.times(rate));
    }, Promise.resolve(BN.ZERO));
    return balancesAmount.plus(BN.ZERO).toFormat(2);
  }

  tokenStats = async (tokenAssetId: string): Promise<TPoolStats | null> => {
    let poolStats =  /:\/\/((\w|[.:])+\/(.+))/.test(window.location.href) ? this.rootStore.lendStore.poolsStats : this.rootStore.lendStore.mergedPoolsStats;
    let initialized = this.rootStore.lendStore.initialized;

    while (!initialized || !poolStats) {
      await new Promise(resolve => setTimeout(resolve, 100)); // wait 100ms and try again
      initialized = this.rootStore.lendStore.initialized;
      poolStats = this.rootStore.lendStore.mergedPoolsStats;
    };

    const tokenIndex = poolStats
      .map((item: TPoolStats) => item.assetId)
      .indexOf(tokenAssetId);

    return tokenIndex !== -1
      ? poolStats[tokenIndex]
      : null;
  };
}
