import { Row, Column } from "@components/Flex";
import SizedBox from "@components/SizedBox";
import Text from "@components/Text";
import { observer } from "mobx-react-lite"
import Skeleton from "react-loading-skeleton";
import TokensPreview from "./TokensPreview";
import styled from "@emotion/styled";
import { IPool } from "@src/constants";
import BN from "@src/utils/BN";


interface IProps {
  pool: IPool;
  totalSupply: BN;
  totalBorrow: BN;
  maxSupplyAPY: BN;
}


const CardHeader = styled.div`
  display: flex;
  
  flex-direction: column;
  align-self: stretch;
  padding: 24px;
  gap: 16px;
  background: linear-gradient(180deg, #2B2F76 0%, #4D51B8 100%);
`;

const SupplyBorrowAPY = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  
  @media (min-width: 880px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;


const DesktopMarketCardHeader: React.FC<IProps> = ({
  pool,
  totalSupply,
  totalBorrow,
  maxSupplyAPY
}) => {
  return (
    <CardHeader>
      <Column style={{ width: "100%", flexGrow: 1 }}>
        <Row style={{ width: "100%", flexGrow: 1 }}>
            <Text weight={500} size="bigger" style={{ color: "#FFFFFF" }}>{ pool.name }</Text>
            <TokensPreview poolAddress={pool.address} />
        </Row>
        {pool.description &&
          <>
            <SizedBox height={4} />
            <Text type="secondary" size="medium" style={{ color: "#C6C9F4" }}>{pool.description}</Text>
          </>
        }
      </Column>
        <SupplyBorrowAPY>
            <Column>
                <Text type="secondary" size="medium" style={{ color: "#C6C9F4" }}>Total supplied</Text>
                {
                    totalSupply.lte(0)
                        ? <Skeleton height={24} width={64}></Skeleton>
                        : <Text size="medium+" weight={500} style={{ color: "#FFFFFF" }}>$ {totalSupply.toBigFormat(2)}</Text>
                }
            </Column>
            <Column>
                <Text type="secondary" size="medium" style={{ color: "#C6C9F4" }}>Total borrowed</Text>
                {
                    totalBorrow.lte(0)
                        ? <Skeleton height={24} width={64}></Skeleton>
                        : <Text size="medium+" weight={500} style={{ color: "#FFFFFF" }}>$ {totalBorrow.toBigFormat(2)}</Text>
                }
            </Column>
            <Column>
                <Text type="secondary" size="medium" style={{ color: "#C6C9F4" }}>Max APY</Text>
                {
                    maxSupplyAPY.lte(0)
                        ? <Skeleton height={24} width={64}></Skeleton>
                        : <Text size="medium+" weight={500} style={{ color: "#FFFFFF" }}>{maxSupplyAPY.toBigFormat(2)} %</Text>
                }
            </Column>
        </SupplyBorrowAPY>
    </CardHeader>
  )
}

export default observer(DesktopMarketCardHeader);
