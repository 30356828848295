import styled from "@emotion/styled";
import { useStores } from "@src/stores";
import { TPoolStats } from "@src/stores/LendStore";
import { observer } from "mobx-react-lite"
import React from "react";

interface IProps {
  poolAddress: string;
  direction?: "left" | "right";
};

const TokensContainer = styled.div`
  display: flex;
  padding: 4px 0;
`;

const TokenPreviewRight = styled.img`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: -6px;
  clip-path: path('M 0 0 L 32 -2 a 1 1 0 0 0 -4 28 L 0 24 Z');

  &:last-of-type {
    margin-right: 0;
    clip-path: none;
  }
`;

const TokenPreviewLeft = styled.img`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-left: -6px;
  clip-path: path('M 30 0 L -2 -2 a 1 1 0 0 1 -4 28 L 30 24 Z');

  &:first-of-type {
    margin-left: 0;
    clip-path: none;
  }
`;

const TokensPreview: React.FC<IProps> = ({ poolAddress, direction }) => {
  const { lendStore } = useStores();

  const [tokensStats, setTokensStats] = React.useState<TPoolStats[]>([]);
  const fetchTokens = async () => {
    while (!tokensStats || !tokensStats.length) {
      await new Promise(resolve => setTimeout(resolve, 100)); // wait 100ms and try again
      setTokensStats(lendStore.getStatsForPool(poolAddress).filter((s: TPoolStats) => s.paused.isZero()));
    }
  }
  fetchTokens();

  return (
    <TokensContainer>
      {
        tokensStats.sort((a, b) => direction === "left" ? b.totalSupply.minus(a.totalSupply).toNumber() : a.totalSupply.minus(b.totalSupply).toNumber()).map((t) => (
          direction === "left"
            ? <TokenPreviewLeft src={t.logo} alt={t.symbol} key={t.symbol} />
            : <TokenPreviewRight src={t.logo} alt={t.symbol} key={t.symbol} />
        ))
      }
    </TokensContainer>
  );
};

export default observer(TokensPreview);
